<template>
  <CRow v-if="platformPermissionsLoaded && checkPermission('service.forms.submissions')" class="service form_submissions">
    <CCol cols="12" lg="12" md="12">
      <CCard class="mb-0">
        <CCardHeader class="pb-0">
          <CRow>
            <CCol cols="12" xl="12" class="pt-0 text-left">
              {{$t('forms.Form_submissions')}}
            </CCol>
          </CRow>
        </CCardHeader>
        <CCardBody class="p-0">          
          <div class="submission_filters">
            <CRow class="m-0">
              <CCol cols="4" lg="4" class="pt-0 pb-0 search">
                <label><b>{{$t('forms.Search_within_form_submissions')}}</b></label>
                <CInput type="text" class="mb-0 searchbar" v-model="submissionFilters.search_term" v-debounce:1s="filterFormSubmissions"></CInput>
              </CCol>
              <CCol cols="4" lg="4" class="pt-0 pb-0">
                <label><b>{{$t('common.Filter_on_category')}}</b></label>
                <multiselect class="data_table open_absolute"
                              v-model="selectedCategories" 
                              :options="categories" 
                              :multiple="true"                
                              :placeholder="$t('common.Select_category')"
                              :selectLabel="$t('common.Add_category')" 
                              :selectedLabel="$t('Added')"
                              :deselectLabel="$t('common.Remove_category')"
                              track-by="form_category_id_external" 
                              label="form_category_name"
                              @search-change="asyncFindCategory"
                              @input="filterFormSubmissions()">
                  <span slot="noResult">{{ $t('common.no_categories_found') }}</span>
                  <span slot="noOptions">{{ $t('common.start_typing_to_search') }}</span>
                </multiselect>
              </CCol>
              <CCol cols="4" lg="4" class="pt-0 pb-0">
                <label><b>{{$t('forms.Filter_on_form')}}</b></label>
                <multiselect class="data_table open_absolute"
                              v-model="selectedForms" 
                              :options="forms" 
                              :multiple="true"                
                              :placeholder="$t('forms.Select_form')" 
                              :selectLabel="$t('forms.Add_form')" 
                              :selectedLabel="$t('Added')"
                              :deselectLabel="$t('forms.Remove_form')"
                              track-by="form_id_external" 
                              label="form_name"
                              @search-change="asyncFindForm"
                              @input="filterFormSubmissions()">
                  <span slot="noResult">{{ $t('forms.No_forms_found') }}</span>
                  <span slot="noOptions">{{ $t('common.start_typing_to_search') }}</span>
                </multiselect>
              </CCol>        
            </CRow>
          </div>
          <hr class="mt-2_5" v-bind:class="{'mb-2_5' : submissions.length > 0, 'mb-0' : submissions.length == 0}">
          <div class="data_table_container">
            <CRow v-if="submissionsLoading === true">
              <CCol cols="12" lg="12">
                <loadingSpinner mode="auto" :content="$t('common.Loading')"/>
              </CCol>
            </CRow>
            <CRow v-else>
              <CCol cols="12" lg="12" v-bind:class="{'pt-0 pb-0': submissions.length > 0}">
                <div v-if="submissions.length > 0">
                  <b-table ref="submissionsTable"
                           class="data_table includes_dropdown"
                           :data="submissions"
                           :striped="true"
                           :hoverable="true"
                           :mobile-cards="true"
                           :current-page.sync="currentPage"
                           backend-pagination
                           :paginated="isPaginated"
                           :total="totalItems"
                           :per-page="perPage"
                           :pagination-simple="isPaginationSimple"
                           :pagination-position="paginationPosition"
                           @page-change="onPageChange"
                           backend-sorting
                           :default-sort="[sortField, sortOrder]"
                           :default-sort-direction="defaultSortDirection"
                           :sort-icon="sortIcon"
                           :sort-icon-size="sortIconSize"                   
                           @sort="onTableSort">
                    
                    <template slot-scope="props">
                      <b-table-column field="image" label="" :searchable="false" :sortable="false" width="2.5%">
                        <userProfilePopover ref="userProfilePopover" mode="user" :userName="props.row.submitter" :userProfileImage="props.row.submitter_profile_image" :userIdExternal="props.row.submitter_user_id_external" :senderIdExternal="null" popoverPlacement="right"/>
                      </b-table-column>                  
                      <b-table-column field="submitter" :label="$t('forms.Submitter')" :searchable="searchEnabled" :sortable="true" width="20%">
                        <span>{{props.row.submitter}}</span>
                        <span class="d-block meta mt-1">
                          <span>{{props.row.team_name}} ({{props.row.department_name}})</span>
                          <span v-if="props.row.work_location"> | {{props.row.work_location}}</span>
                        </span>
                      </b-table-column>
                      <b-table-column field="form_name" :label="$t('forms.Form_name')" :searchable="searchEnabled" :sortable="true" width="20%">
                        <span>{{props.row.form_name}}</span>
                      </b-table-column>
                      <b-table-column field="form_category_name" :label="$t('forms.Form_category')" :searchable="searchEnabled" :sortable="true" width="20%">
                        <span>{{props.row.form_category_name}}</span>
                      </b-table-column>                 
                      <b-table-column field="submitted" :label="$t('forms.Submitted_at')" :searchable="searchEnabled" :sortable="true" width="20%">
                        <span>{{props.row.submitted | moment("DD-MM-YYYY HH:ss")}}</span>
                      </b-table-column>
                      <b-table-column field="form_routes" :label="$t('forms.Form_routes')" width="20%">
                        <b-tag v-for="route in props.row.form_routes" v-bind:key="route.form_route_id_external" class="mt-1 info">{{route.route_name}}</b-tag>
                        <span v-if="props.row.form_routes.length === 0">-</span>
                      </b-table-column>
                      <b-table-column field="actions" label="" width="10%">
                        <div class="d-flex justify-content-lg-end">
                          <CDropdown color="primary" toggler-text="" :caret="false" placement="bottom-end" class="ml-2 table_actions_dropdown">
                            <div slot="toggler-content">
                              <span class="d-flex align-items-center">
                                {{$t('common.Actions')}}<i class="fas fa-caret-down ml-1"></i>
                              </span>
                            </div>

                            <CDropdownItem>
                              <div class="d-flex align-items-center" @click="openSidebarRight('form_submission_details', { form_submission_id_external: props.row.form_submission_id_external });">
                                <div class="post_action_icon text-center mr-1">
                                  <i class="fa-solid fa-chevron-right"/>
                                </div>
                                <div class="flex-grow-1">
                                  <span>{{$t('forms.Show_submission')}}</span>
                                </div>
                              </div>
                            </CDropdownItem>
                            <CDropdownItem>
                              <div class="d-flex align-items-center" @click="showFormSubmissions(props.row.form_id_external)">
                                <div class="post_action_icon text-center mr-1">
                                  <i class="fa-solid fa-inbox"/>
                                </div>
                                <div class="flex-grow-1">
                                  <span>{{$t('forms.Show_all_submissions')}}</span>
                                </div>
                              </div>
                            </CDropdownItem>
                          </CDropdown>
                        </div>
                      </b-table-column>
                    </template>
                    <template slot="empty">
                      <div class="p-2 text-center">
                        <span>{{$t('forms.No_form_submissions_available')}}</span>                    
                      </div>                
                    </template>                
                  </b-table>
                </div>
                <div v-else class="text-center">
                  <span v-if="submissionFilters.search_term || selectedCategories.length > 0 || selectedForms.length > 0">{{$t('forms.No_forms_based_on_filters')}}</span>
                  <span v-else>{{$t('forms.No_form_submissions_available')}}</span>
                </div>                
              </CCol>
            </CRow>
          </div>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
  <noPermission v-else-if="platformPermissionsLoaded" trigger="permission"/>
</template>

<script>
import axios from 'axios'
import QuickEdit from 'vue-quick-edit';
import Multiselect from 'vue-multiselect';

import loadingSpinner from '@/components/common/loadingSpinner.vue';
import noPermission from '@/components/common/noPermission.vue';
import userProfilePopover from '@/components/common/userProfilePopover.vue';

export default {
  name: 'Resources',
  components: {
    QuickEdit,
    Multiselect,
    loadingSpinner,
    noPermission,
    userProfilePopover
  },
  data() {
    return {
      apiBaseUrl: null,
      clientToken: null,      
      platformPermissions: [],
      platformPermissionsLoaded: false,      
      submissions: [],
      submissionsLoading: true,
      submissionsLoaded: false,
      submissionFilters: {
        search_term: '',
        categories: [],
        forms: []
      },
      selectedCategories: [],
      selectedForms: [],
      categories: [],
      forms: [],
      sortField: 'submitted',
      sortOrder: 'desc',
      defaultSortDirection: 'desc',
      isPaginated: false,
      isPaginationSimple: false,
      paginationPosition: 'bottom',
      currentPage: 1,
      perPage: 10,
      sortIcon: 'arrow-up',
      sortIconSize: 'is-small',
      searchEnabled: false,
      totalItems: 0,
      submissionsStartIndex: 0
    }
  },
  methods: {
    getFormSubmissions() {
      // Start the loader
      if(this.submissionsLoaded === false) this.submissionsLoading = true;
      // Set the params
      let params = {};
      params.submissionFilters = this.submissionFilters;
      params.submissionFilters.sort_field = this.sortField;
      params.submissionFilters.sort_order = this.sortOrder;
      params.submissionFilters.categories = [];
      params.submissionFilters.forms = [];

      for(let c = 0; c < this.selectedCategories.length; c++) {
        params.submissionFilters.categories.push(this.selectedCategories[c].form_category_id_external);
      }

      for(let f = 0; f < this.selectedForms.length; f++) {
        params.submissionFilters.forms.push(this.selectedForms[f].form_id_external);
      }

      // Get the submissions
      axios.post(process.env.VUE_APP_API_URL + '/v1/service/forms/submissions/' + this.submissionsStartIndex, params)
      .then(res => {
        // Clear the submissions array
        this.submissions = [];
        // Set the default currentTotal value
        let currentTotal = res.data.data.total_submissions;
        // Update the currentTotal value
        if(currentTotal / this.perPage > 1000) currentTotal = this.perPage * 1000;
        // Update the totalItems value
        this.totalItems = currentTotal;
        // Add the submissions to the submissions array
        this.submissions = res.data.data.submissions;
        // Enable the pagination if necessary
        (this.totalItems > this.perPage) ? this.isPaginated = true : this.isPaginated = false;
        // Stop the loader
        this.submissionsLoading = false;
        // Update the submissionsLoaded value
        this.submissionsLoaded = true; 
      })
      .catch(err => {
        console.error(err); 
      });
    },
    filterFormSubmissions() {
      // Reset the pagination;
      this.resetPagination();
      // Get the submissions
      this.getFormSubmissions();
    },
    showFormSubmissions(formIdExternal) {
      this.$router.push({path: `/service/form/${formIdExternal.toString()}/submissions`});
    },            
    resetPagination() {
      this.currentPage = 1;
      this.submissionsStartIndex = 0;
    },
    onPageChange(page) {
      // Set the submissionsStartIndex value
      (page > 1) ? this.submissionsStartIndex = (page - 1)*10 : this.submissionsStartIndex = 0;
      // Get the rewards
      this.getFormSubmissions();
    },
    onTableSort(field, order) {
      this.sortField = field;
      this.sortOrder = order;
      // Reset the pagination;
      this.resetPagination();
      // Get the submissions
      this.getFormSubmissions()
    },        
    asyncFindCategory(query) {
      let searchTerm = query;
      this.categories = [];
      
      if(searchTerm.length >= 2) {      
        axios.get(process.env.VUE_APP_API_URL + '/v1/common/search/service/forms/category/' + searchTerm)
        .then(res => {      
          this.categories = res.data.data;
        })
        .catch(err => {
          console.error(err); 
        });
      }   
    },
    asyncFindForm(query) {
      let searchTerm = query;
      this.forms = [];

      if(searchTerm.length >= 2) {
        axios.get(process.env.VUE_APP_API_URL + '/v1/common/search/service/forms/' + searchTerm)
        .then(res => {      
          this.forms = res.data.data;    
        })
        .catch(err => {
          console.error(err); 
        });
      }   
    },          
    getPlatformPermissions() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/platform/permissions')
      .then(res => {      
        this.platformPermissions = res.data.data;
        // Update the platformPermissionsLoaded value
        this.platformPermissionsLoaded = true;
      })
      .catch(err => {
        console.error(err); 
      });
    },
    checkPermission(permissionTag) {
      if(this.platformPermissions.includes(permissionTag)) {
        return true;
      } else{
        return false;
      }
    }          
  },
  mounted: function() {
    this.apiBaseUrl = process.env.VUE_APP_API_URL;
    this.clientToken = localStorage.getItem('token');
        
    this.getPlatformPermissions();
    this.getFormSubmissions();
  }
}
</script>